.clipLoader {
    background-color: white;
    width: 100%;
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
}

.mainContentContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
    gap: 20px;
    background-color: white;
    width: 100%;
}