.wrapper-ph {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  z-index: 2;
  background-color: white;
}
.option-button-text-ph {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
  margin-left: 15px;
}
.list-item-month-ph{
  list-style-type: none;

}
.active-list-item-month-ph{
  padding:5px;
  background: #ffffff;
  list-style-type: none;
}
.option-text-ph {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  text-align: center;
}
.filter-text-option-ph {
  text-align: center;
  font-family: "Mulish";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  margin-top: 6px;
  margin-bottom: 6px;
}
#filter-button-ph {
  border: 0;
  border-radius: 5px;
  background: #f4f4f4;
  width: 180px;
  padding: 6px 8px;
  position: relative;
  text-align: center;
  font-family: "Mulish";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;

}

#filter-button-ph:hover {
  cursor: pointer;
}

#filter-button-ph::after {
  position: absolute;
  left: 6px;
  top: 4px;
}

.options-ph {
  border: 1px solid #ffffff;
  display: none;
  list-style: none;
  padding: 4px 0;
  margin-top: -4px;
  font-family: "Mulish";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  overflow-y: auto;
}
ul.show-ph {
  z-index: 100;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  min-width: 180px;
  border: 2px solid #f4f4f4;
}
.ul-offset-show-0-ph {
  margin-left: -45px;
  width: 120px;
}

.ul-offset-show-1-ph {
  margin-left: -11px;
  width: 120px;
}

.ul-offset-show-2-ph {
  margin-left: px;
  width: 140px;
}
ul.options-ph li {
  /* padding: 6px 10px; */
  /* text-align: center; */
  font-size: 13px;
}
ul.options-ph li:active,
ul.options-ph li:focus,
ul.options-ph li:hover,
ul.options-ph li[aria-selected="true"] {
  /* background: #f3f3f3; */
  cursor: pointer;
}
.active-list-item-month-ph{
  background-color: #f4f4f4;
  border-radius: 10px;
  padding-left: 20px;
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.filter-header-ph{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.list-item-month-ph{
  border-radius: 10px;
  padding-left: 20px;
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.month-list-options{
  width: 100%;
}

.list-item-month-ph:hover{
  background-color: #f4f4f4;
  
}
.clear-button:hover{
  cursor: pointer;
}