.mainContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: block;
    z-index: 998;
    font-family: "Mulish";
}

.clipLoader {
	display:flex;
	justify-content: center;
	align-items: center;
	height:50vh;

}

.headerContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	line-height: 0;
	padding-top: 15px;

}

.innerContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	height: 100%;
	width: 100%;
	max-width: 1200px;
	margin: auto;
	padding: 0 20px 20px;
	
}

.title {
	font-size: 25px;
	font-weight: 600;
	margin-left: 30px;
	margin-right: 5px;
}

.cancelBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: 100px;
	height: 30px;
	padding: 25px;
	background-color: black;
	color: white;
	border: none;
	border-radius: 4px;
	justify-self: flex-end;
	font-size: 18px;
	font-family: "Mulish";
}

.mainContentContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 20px;
    gap: 20px;
	width: 100%;
}

.checkboxContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
    padding-left: 60px;
    
}

.customCheckmark {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.label {
    font-weight: 600;
    margin: 0 10px;

}

.customCheckmark .checkmark {
    min-width: 35px;
    min-height: 35px;
    max-width: 35px;
    max-height: 35px;
    border: 1px solid black;
    border-radius: 8px;
    display: inline-block;
    background-color: white;
    background: #222 url(https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/White_check.svg/1200px-White_check.svg.png) center/1250% no-repeat;
}

.customCheckmark input:checked + .checkmark {
    background-size: 60%;
  }

.customCheckmark input {
    display: none;
}

.btnLoader {
	margin: 0 10px;
}

.submitBtn {
    display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: 40%;
	height: 30px;
	padding: 25px;
	border: none;
	border-radius: 4px;
	justify-self: flex-end;
	font-size: 18px;
	font-family: "Mulish";
    background-color: black;
	color: white;
    margin: 200px auto 0px;
}

@media(max-width: 948px) {
	.innerContainer{
		padding: 0 20px 20px;
	}

	.submitBtn {
		width: 80%;
	}
}

@media(max-width: 510px) {
	.title {
		font-size: 18px;
	}
}