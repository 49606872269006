.container-notification-modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;  
    z-index:    99999;
    width: 100%;
    height: 100%;
    /* background-color:  #000000b0 ; */
    
    background-color:  #34A338 ;
}
.box-notification-modal{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* border-radius: 5px; */
    /* box-shadow: 0 0 5px #000000c0; */
    /* width: 30%;
    height: 30%; */
    color: #fff;
    font-size: 20px;
    text-align: center; 
}
.clipLoader {
    background-color: white;
    width: 100%;
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
}