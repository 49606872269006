.mainContainer {
		/* position: fixed; */
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: white;
		display: block;
		z-index: 997;
		font-family: "Mulish";
}

.innerContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	height: 100%;
	width: 100%;
	max-width: 1200px;
	margin: auto;
	padding: 0 20px 20px;
	z-index: 997;
	
}

.headerContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	line-height: 0;
	padding-top: 15px;
}

.innerHeaderContainer{
	display: flex;
	align-items: center;
	justify-content: space-between;
	width:100%;
}

.title {
	font-size: 25px;
	font-weight: 700;
	margin-left: 30px;
	margin-right: 5px;
}

.cancelBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: 100px;
	height: 30px;
	padding: 25px;
	background-color: black;
	color: white;
	border: none;
	border-radius: 4px;
	justify-self: flex-end;
	font-size: 18px;
	font-family: "Mulish";
}

.mainContentContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 20px;
    gap: 20px;
	width: 100%;
}

.pickupContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f4f4f4;
	border-radius: 10px;
	height: 75px;
	width: 100%;
	padding: 10px;
	font-size: 20px;
	font-weight: 700;
	gap:5px;
}

.customerContainer {
	display:flex;
	flex-direction: column;
	background-color: #f4f4f4;
	border-radius: 10px;
	width: 100%;
	padding: 10px;
	gap:10px;
}

.customerContainer > * {
	display:flex;
	flex-direction: column;
	background-color: white;
	border-radius: 10px;
	width: 100%;
	padding: 20px;
	gap: 10px;
	flex-grow: 1;	
}
.receiptHeaders {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.receiptValues {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 600;
	font-size: 20px;
}

.totalItem {
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: flex-end;
	width: 100%;
}

#total {
	font-weight:600;
}

.btnContainer {
	margin-top: 60px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: 50px;

}

.btnContainer > * {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: 40%;
	height: 30px;
	padding: 25px;
	border: none;
	border-radius: 4px;
	justify-self: flex-end;
	font-size: 18px;
	font-family: "Mulish";

}

.btnLoader {
	margin: 0 10px;
}

.readyBtn {
	background-color: #dfdfdf;
	color: black;
	display: flex;
	justify-content: center;
	align-items: center;
}
.confirmBtn{
	background-color: black;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}


.clipLoader {
	display:flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;;
}

@media(max-width: 948px) {
	.innerContainer{
		padding:0 20px 20px;
	}
	.title {
		margin-left: 10px;
	}
	.btnContainer{
		flex-direction: column-reverse;
		gap:25px;
	}

	.btnContainer > * {
		width: 80%;
	}
	.pickupContainer{
		flex-direction: column;
	}
}
