.modalOverlay {
    position: fixed;
    top:0;
    right: 0;
    height:100%;
    width:100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family:'Mulish';
    z-index: 2;
}


.deleteModal {
    position: relative;
    background-color: white;
    min-height: 100px;
    width: 300px; 
    height: 125px;
    padding: 10px;
    font-weight: 600;
}

.btnContainer {
    margin-top: 30px;
    height: 100%;
    display:flex;
    justify-content: space-between;
}


.btnStyle {
    background-color: black;
    color: white;
    cursor: pointer;
    height: 36.5px;
    width: 65px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-family: "Mulish";
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}