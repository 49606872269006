.modalOverlay {
    position: fixed;
    top:0;
    right: 0;
    height:100%;
    width:100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family:'Mulish';
    z-index: 2;
}

.emailModal {
    position: relative;
    background-color: white;
    min-height: 100px;
    width: fit-content; 
    padding: 10px;
    min-width: 150px
}

.btnStyle {
    background-color: black;
    color: white;
    cursor: pointer;
    height: 36.5px;
    width: 65px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-family: "Mulish";
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.confirmBtn {
    position: absolute;
    bottom: 6px;
    right: 6px;
}

.emailStyle {
    text-decoration: underline;
    cursor: pointer;
    color: #2036ff;
}
