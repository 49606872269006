.whiteSpace {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: white;
	width: 100%;
}

.container {
	position: relative;
	background-color: white;
	font-family: "Mulish";
	/* height: 100%; */
	width: 100%;
	padding: 15px 15px 0 15px;
	margin: auto;
	display: flex;
	flex-direction: column;
}

.navbar {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.title {
	font-weight: 600;
	font-size: 20px;
}

.cancelBtn {
	/* position: absolute; */
	/* left: 0px; */
	cursor: pointer;
	display: flex;
}

#editTable {
	border-collapse: collapse;
	width: 100%;
	margin-top: 20px;
}

#editTable tr {
	display: flex;
	align-items: center;
	gap: 20px;
	background-color: #f4f4f4;
	margin-top: 10px;
	border-radius: 10px;
	padding: 10px;
	font-size: 16px;
	font-weight: 600;
	width: 100%;
}

#editTable td {
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	border-radius: 8px;
}

#timeSelectors {
	display: flex;
	align-items: center;
	gap: 10px;
	flex: 0.5;
}

#weekDay {
	flex: 0.2;
	background-color: white;
}

#selectorSpacer {
	flex: 0.4;
	background-color: white;
}

#openCheck {
	flex: 0.2;
}

#closeCheck {
	flex: 0.2;
}

.customCheckmark {
	cursor: pointer;
	display: flex;
	align-items: center;
	margin: 10px 0;
}

.label {
	font-weight: 700;
	margin: 0 10px;
}

.customCheckmark .checkmark {
	min-width: 25px;
	min-height: 25px;
	max-width: 25px;
	max-height: 25px;
	border: 1px solid white;
	border-radius: 5px;
	display: inline-block;
	background-color: white;
	background: #222
		url(https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/White_check.svg/1200px-White_check.svg.png)
		center/1250% no-repeat;
}

.customCheckmark input:checked + .checkmark {
	background-size: 60%;
}

.customCheckmark input {
	display: none;
}

.customSelect {
	/* position: relative; */
	display: inline-block;
	color: white;
	width: 100%;
}

.customSelect select {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
	width: 100%;
	min-height: 50px;
	background: white;
	border-radius: 8px;
	border: none;
	outline: none;
	font-size: 16px;
	font-weight: 600;
	font-family: "Mulish";
	text-align: center;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	/* -webkit-appearance: listbox !important; */
	background-image: url("../../../../assets/arrow.png");
	background-position: right 10px center;
	background-size: 12px;
	background-repeat: no-repeat;
	color: #000;
}

.saveBtn {
	margin: 30px auto;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: black;
	color: white;
	cursor: pointer;
	min-height: 45px;
	width: 40%;
	border: none;
	border-radius: 6px;
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.saveBtnText {
	margin: 0 10px;
}

@media (max-width: 948px) {
	.container {
		padding: 20px;
	}

	.navbar {
		position: relative;
		left: -12px;
	}

	/* .title {
        margin-left: 10px;
    } */

	.cancelBtn {
		/* position: relative; */
		left: 0px;
	}

	#editTable tr {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-template-rows: repeat(3, minmax(0, 1fr));
		background-color: #f4f4f4;
		margin-bottom: 20px;
		border-radius: 10px;
		padding: 10px;
		font-size: 16px;
		font-weight: 600;
	}

	#weekDay {
		grid-column-start: 1;
		grid-column-end: 3;
		background-color: white;
	}

	#timeSelectors {
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 2;
		grid-row-end: 3;
	}

	#closeCheck {
		grid-column-start: 1;
		grid-column-end: 2;
		grid-row-start: 3;
		grid-row-end: 4;
	}

	#openCheck {
		grid-column-start: 2;
		grid-column-end: 3;
		grid-row-start: 3;
		grid-row-end: 4;
	}

	#selectorSpacer {
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 2;
		grid-row-end: 3;
	}

	.saveBtn {
		width: 80%;
	}
}

@media (max-width: 360px) {
	.customSelect select {
		background-position: right 5px center;
        background-size: 5px;
	}
}
