.mainContainer {
	max-width: 1200px;
	margin: auto;
	/* padding: 15px; */
	font-family: "Mulish";
	height: 100vh;
}

.headerContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	line-height: 0;
	padding: 15px 15px 0 15px;
}

.title {
	font-size: 25px;
	margin-left: 5px;
	margin-right: 5px;
	font-family: "Mulish";
	font-weight: 600;
}

.cellTitle {
	margin-left: 15px;
}

.mainContentContainer {
	display: flex;
	flex-direction: column;
	/* height: 80vh; */
	justify-content: space-between;
	margin-top: 20px;
}
.contentContainer {
	width: 100%;
}
.cell {
	display: flex;
	flex-direction: column;
	text-decoration: none;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid #dfdfdf;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
	color: #000;
	padding: 15px;
}

.cell2 {
	display: flex;
	flex-direction: column;
	text-decoration: none;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid #dfdfdf;
	align-items: center;
	padding: 15px;
	text-decoration: none;
}
.cell:visited,
.cell:hover,
.cell:active {
	text-decoration: none;
	color: #000;
}

.cellHeader {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	line-height: 0;
}

.btnContainer {
	display: flex;
	align-items: center;
	/* justify-content: flex-end; */
	padding: 0 15px 0 15px;
	margin-top: 15px;
}

.btnColumn {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.btn {
	width: 125px;
	height: 30px;
	border-radius: 5px;
	background-color: #000;
	color: #fff;
	cursor: pointer;
}

@media screen and (max-width: 350px) {
	.cellHeader {
		line-height: 1;
		word-break: break-word;
	}
}
