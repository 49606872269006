.mainContainer {
	width: 100%;
	height: 100%;
	display: flex;
	max-width: 1200px;
	margin: auto;
	padding: 20px;
	margin: auto;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
}
