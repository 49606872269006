.mainContainer {
	display: flex;
	flex-direction: column;
	font-size: 17px;
	/* width: 250px; */
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1;
	left: 0;
    flex: 0.2;
	margin-right: 20px;
	font-family: "Mulish";
}

/* filter container */

.filterMainContainer {
	background-color: #eeeeee;
	border-radius: 6px;
	width: 100%;
	height: 180px;
	display: flex;
	flex-direction: column;
}
.filterCell {
	display: flex;
	flex: 33%;
	align-items: center;
	justify-content: center;
	border-bottom: 2px solid #e7e7e7;
	font-weight: 700;
	cursor: pointer;
}
.filterCell:last-child {
	border: none;
}

/* link containers */

.linkContainer {
	display: flex;
	flex-direction: column;
}

.link {
	background-color: #eeeeee;
	border-radius: 6px;
	width: 100%;
	height: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-weight: 800;
	text-decoration: none;
	color: #000;
	margin-top: 15px;
	cursor: pointer;
}

.filterCell:hover .innerDiv {
	background-color: #f7f7f7;
	width: 95%;
	height: 80%;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.link:hover .innerDiv {
	background-color: #f7f7f7;
	width: 95%;
	height: 80%;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.activeDiv {
	background-color: #f7f7f7;
	width: 95%;
	height: 80%;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width:950px){
	.mainContainer{
		flex: 0.3;
		margin-right: 10px;
	}
}
@media screen and (max-width:600px){
	.mainContainer{
		flex: 0.3;
		margin-right: 10px;
		font-size: 14px;
	}
}
@media screen and (max-width:475px){
	.mainContainer{
		flex: 0.4;
		margin-right: 10px;
		font-size: 11px;
	}
}
