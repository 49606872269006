.whiteSpace {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    width: 100%;
    height: 100%;
}

.container {
    position: relative;
    background-color: white;
    font-family:'Mulish';
    /* height: 100%; */
    width: 100%;
    padding: 15px 15px 0 15px;
    margin:auto;
    display:flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

.navbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 0;
}

.title {
    font-weight: 600;
    font-size: 20px;
 }

 .cancelBtn {
    left: 0px;
}

.calendarCard {
    position: relative;
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content:center;
    align-items: center;
    margin: 30px 0;
    background-color: #f4f4f4;
    border-radius: 8px;
}

.selectTimeRow {
    display: flex;
    align-items:center;
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 8px;
    gap: 20px;
    /* font-size: 20px; */
    font-weight: 700;
}

.selectTimeRow > * {
    max-height: 50px;
    min-height: 50px;
    border-radius: 8px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.time {
    flex: 0.2;
    background-color: white;
}

.selectorSpacer {
    flex: 0.4;
    background-color: white;
}

.timeSelectors {
    display:flex;
    align-items: center;
    gap:10px;
    flex:0.4;
}

#openCheck {
    flex: 0.2;
}

#closeCheck {
    flex: 0.2;
}

.customCheckmark {
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 25px;
}

.label {
    margin: 0 10px;
}

.customCheckmark .checkmark {
    min-width: 35px;
    min-height: 35px;
    max-width: 35px;
    max-height: 35px;
    border: 1px solid white;
    border-radius: 8px;
    display: inline-block;
    background-color: white;
    background: #222 url(https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/White_check.svg/1200px-White_check.svg.png) center/1250% no-repeat;
}

.customCheckmark input:checked + .checkmark {
    background-size: 60%;

  }

.customCheckmark input {
    display: none;
}

.customSelect {
    position: relative;
    display: inline-block;
    color: white;
    width: 100%;
}

.customSelect select {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    width: 100%;
    min-height: 50px;
    background: white;
    border-radius: 8px;
    border:none;
    outline: none;
    font-size: 16px;
    font-family: "Mulish";
    font-weight: 700;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none; 
    appearance: none; 
    color: #000;
    background-image: url("../../../../assets/arrow.png");
    background-position: right 10px center;
    background-size: 12px;
    background-repeat: no-repeat;
}

/* .placeholderSelected select {
    padding-right: 30px;
} */

.btnStyle {
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    cursor: pointer;
    min-height: 45px;
    width: 40%;
    border: none;
    border-radius: 6px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.saveBtn {
    margin: 30px auto;
}

.deleteBtn {
    margin: 0 auto;
}

@media (max-width: 948px) {
    .container {
        padding: 20px;
        position:fixed;
    }

    .navbar {
        position: relative;
        left: -12px;
    }    
    
    .title {
        margin-left: 10px;
    }

    .cancelBtn {
        position: relative;
        left: 0px;
    }

    .selectTimeRow {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: repeat(3, minmax(0, 1fr));
    }

    .time {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .timeSelectors {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
        align-items: center;
        flex:0.4;
    }

    #closeCheck {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
    }
    
    #openCheck {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 4;
    }
    
    .selectorSpacer {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
    }
   
    .saveBtn {
        width: 80%;
    }

    .deleteBtn {
        width: 80%;
    }
} 

@media (max-width: 360px) {
	.customSelect select {
		background-position: right 5px center;
        background-size: 5px;
	}
}
