.Modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 100;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
}
.modal-body{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1200px;
}
.Header-modal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.right-box-body{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100px;
  height: 55%;
}

.header-modal-paymentid {
  flex:0.1;
  font-family: "Mulish";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 10px;
}
.modal-payment-status {
  font-family: "Mulish";
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.modal-status{
  width: 100%;

}
.body-box-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 24px;
  margin-right: 24px;
}
.left-box-container {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  flex: 0.4;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  border-radius: 10px;
}
.left-top-box {
  background-color: #ffffff;
  flex: 0.2;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.left-mid-box {
  background-color: #ffffff;
  flex: 0.4;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.left-bot-box {
  background-color: #ffffff;
  flex: 0.4;
  min-height: 100px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  border-radius: 10px;
}
.right-box-container {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  flex: 0.55;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  border-radius: 10px;

}
.close-button{
  position: absolute;
  right: 20px;
  top: 20px;
}
.right-box {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.right-box-header {
  display: flex;
  align-items: center;
  font-family: "Mulish";
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  height: 16%;
}
.right-box-divider {
  height: 16%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-left: 20px;
  padding-right:20px;
  font-family: 'Mulish';
font-size: 13px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;

}

.right-box-divider-amount{
  height: 16%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-left: 20px;
  padding-right:20px;
  font-family: 'Mulish';
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 20px;

}
.right-box-divider-divider{
    height: 3%;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    color: #ffffff;
}
.not-found{
  height: 100%;
  align-content: center;
}
@media (max-width: 768px) {
    .body-box-container {
        min-width: 300px;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;
        padding-right: 44px;
        margin-right: 0;
        overflow-x: scroll;
    }
    .Modal-container{
        padding-right: 0px;
    }
    .left-box-container{
        margin-bottom: 20px;
    }
    .right-box-container{
        min-height: 60%;
        margin-bottom: 30px;
    }
}
