.mainContainer {
	max-width: 1200px;
	margin: auto;
	/* padding: 15px; */
	font-family: "Mulish";
	/* height: 100vh; */
	padding: 15px 15px 0 15px;
}

.headerContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	line-height: 0;
	/* padding: 15px 15px 0 15px; */
}

.title {
	font-size: 25px;
	margin-left: 5px;
	margin-right: 5px;
	font-family: "Mulish";
	font-weight: 600;
}
.filterContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.layoutContainer {
	width: 95%;
	margin: auto;
	display: flex;
	flex-direction: column;
}
.contentMainContainer {
	/* width: 95%; */
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* margin: auto; */
	margin-top: 20px;
	margin-bottom: 40px;
}

.contentBoxes {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	flex: 0.3;
}

.contentBox {
	width: 80%;
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
}
.chart {
	width: 100%;
	flex: 0.7;
	position: relative;
	overflow-x: auto;
	overflow: auto;
}

.totalBox {
	width: 250px;
	height: 100px;
	background-color: #f4f4f4;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	font-weight: bolder;
	border-radius: 5px;
}

.contentTitle {
	font-size: 14px;
	margin-bottom: 5px;
}

.btnContainer {
	background: #f4f4f4;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	margin-left: 10px;
}

.activeBtnContainer {
	background: #bdbdbd;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	margin-left: 10px;
}

.btn {
	outline: none;
	background: transparent;
	border: none;
	font-weight: bolder;
	font-size: 14px;
	padding: 7px;
	cursor: pointer;
	color: #000;
}

/* no content to display */

.noContent {
	width: 100%;
	height: 80vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "Mulish";
	font-size: 18px;
	font-weight: bolder;
}

@media screen and (max-width: 992px) {
	.contentMainContainer {
		flex-direction: column;
	}
	.contentBoxes {
		flex-direction: row;
		width: 100%;
		align-items: center;
		justify-content: space-between;
	}
	.contentBox {
		width: initial;
		margin-right: 0;
	}
}
@media screen and (max-width: 576px) {
	.contentBoxes {
		flex-direction: row;
		width: 100%;
		align-items: center;
		justify-content: space-between;
	}

	.totalBox {
		width: 200px;
		height: 90px;
	}

	.filterContainer {
		justify-content: space-between;
		margin-top: 15px;
	}
	.activeBtnContainer {
		margin-left: 0;
	}
	.btnContainer {
		margin-left: 0;
	}
}

@media screen and (max-width: 450px) {
	.totalBox {
		width: 150px;
		height: 70px;
	}
}
@media screen and (max-width: 360px) {
	.totalBox {
		width: 100px;
		height: 50px;
		align-self: flex-end;
	}

}
