.spinner {
	display: flex;
	height: 100vh;
	width: 100%;
	justify-content: center;
	align-items: center;
}
.main-container {
	/* max-width: 1200px; */
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: #ffffff;
	min-height: 100vh;
}
.container-loading {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: #ffffff;
	min-height: 100vh;
}
.back-navigation-inventory-management {
	height: 14px;
	width: 16px;
}
.inventory-management-product-name-container {
	background: #f4f4f4;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 7px;
}
.inventory-management-toggle-container {
  background: #f4f4f4;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.toggle-switch-title-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.inventory-management-product-row-container {
	background: #f4f4f4;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 100%;
	margin-bottom: 9px;
}
.inventory-management-title {
	/* margin-left: 11px;
	font-family: "Mulish";
	font-size: 20px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	align-self: center; */

	font-weight: 600;
	font-size: 25px;
	margin-left: 5px;
	margin-right: 5px;
	font-family: Mulish;
	font-weight: 600;
}
.toggle-switch-title {
	font-family: "Mulish";
	font-size: 10px;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 0em;
}
.inventory-management-body {
	width: 100%;
}
.inventory-management-filter-row {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-top: 16px;
	margin-bottom: 16px;
}

.inventory-management-filter {
	display: flex;
}
.inventory-management-filter select {
	display: none;
}
.select-selected {
	background-color: DodgerBlue;
}
.select-selected:after {
	position: absolute;
	content: "";
	top: 14px;
	right: 10px;
	width: 0;
	height: 0;
	border: 6px solid transparent;
	border-color: #fff transparent transparent transparent;
}
.message {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.select-selected.select-arrow-active:after {
	border-color: transparent transparent #fff transparent;
	top: 7px;
}
.select-items div,
.select-selected {
	color: #ffffff;
	padding: 8px 16px;
	border: 1px solid transparent;
	border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
	cursor: pointer;
}
.select-items {
	position: absolute;
	background-color: DodgerBlue;
	top: 100%;
	left: 0;
	right: 0;
	z-index: 99;
}
.select-hide {
	display: none;
}

.select-items div:hover,
.same-as-selected {
	background-color: rgba(0, 0, 0, 0.1);
}
.container {
	/* width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: #ffffff;
	min-height: 100vh; */

	position: relative;
	height: 100vh;
	width: 100%;
	max-width: 1200px;
	margin: auto;
	display: flex;
	flex-direction: column;
	font-family: "Mulish";
	padding: 15px 15px 0 15px;
}
.header-inventory-management {
	width: 100%;
	display: flex;
}
.product-name-title {
	font-family: "Mulish";
	font-size: 10px;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 0em;
	text-align: left;
}
.product-name-text {
	font-family: "Mulish";
	font-size: 16px;
	font-weight: 700;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: center;
	display: flex;
	justify-content: center;
	justify-self: start;
	align-items: center;
}
.price-name-text{
	font-family: "Mulish";
	font-size: 16px;
	font-weight: 700;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 15px;
}
@media (max-width: 468px) {
	.container {
		min-width: 280px;
	}
	.inventory-management-body {
		padding-left: 15px;
		padding-right: 15px;
		width: 100%;
	}
	.header-inventory-management {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		padding-left: 15px;
		padding-right: 15px;
		margin-left: 0;
		margin-right: 0;
		margin-top: 10px;
	}
	.inventory-management-product-row-container {
		margin-bottom: 19px;
	}
	.inventory-management-title {
		/* margin-left: 11px;
		font-family: "Mulish";
		font-size: 20px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: center;
		align-self: center;
		width: 100%; */

		margin-left: 10px;
	}
}
