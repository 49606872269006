#styledInput {
	background-color: #f4f4f4;
	border: none;
	border-radius: 10px;
	width: 100%;
	height: 56px;
	padding: 12px;
	font-size: 18px;
	font-family: "Mulish";
}

.container {
	position: relative;
	height: 100vh;
	width: 100%;
	max-width: 1200px;
	margin: auto;
	display: flex;
	flex-direction: column;
	font-family: "Mulish";
}


.navbar {
	display: flex;
	/* align-items: center; */
	padding: 15px 15px 0 15px;
}

.backBtn {
	left: 0px;
}

.title {
	font-weight: 600;
	font-size: 25px;
	margin-left: 5px;
	margin-right: 5px;
	font-family: Mulish;
	font-weight: 600;
}

.inputFields {
	display: flex;
	flex-direction: column;
	gap: 30px;
	margin-top: 20px;
	padding: 15px;
}

.inputField {
	position: relative;
	height: 100%;
	width: 100%;
	min-width: 250px;
	font-family: "Mulish";
	font-weight: 600;
}

.inputHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.inputEmailHeader {
	display: flex;
	align-items: center;
	cursor: pointer;
}

#required {
	font-size: 14px;
}

.suggestionBox {
	padding: 10px;
	margin-bottom: 5px;
	font-weight: 600;
	font-family: "Mulish";
}

.suggestionBox:last-child {
	border-radius: 0 0 12px 12px;
}

.inputRow {
	display: flex;
	justify-content: space-between;
	gap: 40px;
}

.saveBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: black;
	color: white;
	cursor: pointer;
	height: 45px;
	width: 250px;
	border: none;
	border-radius: 6px;
	font-size: 16px;
	font-family: "Mulish";
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.saveBtnText {
	margin: 0 10px;
}

#clipLoader {
	margin: auto;
}

@media (max-width: 948px) {
	.container {
		height: 100vh;
		width: 100%;
		display: flex;
		flex-direction: column;
		font-family: "Mulish";
	}
	.navbar {
		position: relative;
		display: flex;
		align-items: center;
	}

	.title {
		margin-left: 10px;
	}

	.inputFields {
		display: flex;
		flex-direction: column;
		margin-left: 0;
		gap: 30px;
		margin-top: 30px;
	}

	.inputRow {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		gap: 30px;
	}

	.backBtn {
		position: relative;
		left: 0px;
	}
}
