.Main-Container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  min-width: 450px;
  min-height: 100vh;
}
.Logo-container {
  width: 150px;
  position: absolute;
  top: 0;
  left: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-h1 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #000000;
  font-size: 1.02em;
}
.logo {
  width: 150px;
}
.Body-Container {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 60%;
  max-width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  background-color: #ffffff;
}
.Body-Container-Wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Body-Container-Wrap-Names-input {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}
.Body-Container-Wrap-StoreName-input {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.Body-Container-Wrap-Email-input {
  margin-bottom: 10px;
  width: 100%;
  max-width: 272px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Body-Container-Wrap-PhoneNumber-input {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.Body-Container-Wrap-Password-input {
  margin-bottom: 10px;
  width: 100%;
  max-width: 272px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Body-Container-Wrap-input-nonname {
  margin-bottom: 10px;
  width: 100%;
  max-width: 272px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: none;
  border-radius: 10px;
  width: 100%;
  height: 56px;
  padding: 12px;
  font-size: 18px;
  font-family: "Mulish";
  min-width: 272px;
  background-color: #f4f4f4;
}
.Body-Container-Wrap-label-div {
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.updatePassword-button {
  z-index: 1;
  margin: 0 auto;
  color: white;
  background-color: #000000;
  border: none;
  border-radius: 5px;
  font-family: "Mulish";
  font-size: 14px;
  width: 100%;
  height: 55px;
  max-width: 273px;
  margin-top: 20px;
  margin-bottom: 66px;
}
.updatePassword-button1 {
  z-index: 1;
  margin: 0 auto;
  color: #000000;
  background-color: #f4f4f4;
  border: none;
  border-radius: 10px;
  font-family: "Mulish";
  font-size: 14px;
  width: 100%;
  height: 55px;
  max-width: 272px;
  margin-top: 20px;
  margin-bottom: 29px;
}
.text-to-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text-to-login-text {
  color: #000000;
  font-family: "Mulish";
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}
.text-to-login-button {
  margin-top: 10px;
  color: #000000;
  font-family: "Mulish";
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;

  text-decoration-line: underline;
  transition: font-size 500ms ease-out;
}
.text-to-login-button:hover {
  font-size: 0.9em;
}
.title {
  margin-bottom: 37px;
}
.loading {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}
@media (max-width: 762px) {
  .Logo-container {
    width: 100%;
    top: 0;
    left: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Body-Container-Wrap-input-email {
    background-color: #f4f4f4;
    border: none;
    border-radius: 10px;
    width: 100%;
    height: 56px;
    padding: 12px;
    font-size: 18px;
    font-family: "Mulish";
  }
  .Body-Container {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 100%;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff;
  }
  .Body-Container {
    width: 100%;
  }
  .Main-Container {
    width: 100%;
    min-width: 293px;
  }
}
