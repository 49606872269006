.wrapper {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    z-index: 2;
  }
  .option-button-text {
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px;
    margin-left: 15px;
  }
  .option-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    text-align: center;
  }
  .filter-text-option {
    text-align: center;
    font-family: "Mulish";
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  #filter-button {
    border: 0;
    border-radius: 5px;
    background: #f4f4f4;
    width: 180px;
    padding: 6px 8px;
    position: relative;
    text-align: center;
    font-family: "Mulish";
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
  }
  
  #filter-button:hover {
    cursor: pointer;
  }
  
  #filter-button::after {
    position: absolute;
    left: 6px;
    top: 4px;
  }
  
  .options {
    border: 1px solid #ffffff;
    display: none;
    list-style: none;
    padding: 4px 0;
    margin-top: -4px;
    font-family: "Mulish";
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
  ul.show {
    z-index: 100;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    min-width: 180px;
    border: 2px solid #f4f4f4;
  }
  .ul-offset-show-0 {
    margin-left: -45px;
    width: 120px;
  }
  
  .ul-offset-show-1 {
    margin-left: -11px;
    width: 120px;
  }
  
  .ul-offset-show-2 {
    margin-left: px;
    width: 140px;
  }
  ul.options li {
    padding: 6px 10px;
    text-align: center;
    font-size: 13px;
  }
  
  ul.options li:active,
  ul.options li:focus,
  ul.options li:hover,
  ul.options li[aria-selected="true"] {
    background: #f3f3f3;
    cursor: pointer;
    border-radius: 10px;
    margin-left: 3px;
    margin-right: 3px;
  }
  