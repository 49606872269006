.taskContainer {
    display: flex;
    align-items: center;
    background-color: #f4f4f4;
    padding: 10px;
    width: 100%;
    height: 75px;
    border-radius: 10px;
    gap: 10px;
    margin-bottom: 10px;
}

.taskContainer > * {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height:90%;
}

.idContainer {
    flex: 0.25;
    background-color: white;
}

.orderContainer {
    flex: 0.25;
    background-color: white;
}

.statusContainer {
    flex: 0.25;
    background-color: white;
    display: flex;
    flex-direction: column;
}
.datesContainer {
    flex: 0.25;
    background-color: white;
    display: flex;
    flex-direction: column;
}
.respondBtn {
    flex: 0.166;
    color: white;
    background-color:  black;
    cursor: pointer;
}


@media(max-width: 948px) {
    .taskContainer{
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: repeat(3, minmax(0, 1fr));
        margin-bottom: 10px;
        width: 100%;
        height: 100%;
        row-gap: 20px;

    }
    .paidwhenandhowmuch{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
    }
    .taskContainer > * {
        height:100%;
        padding: 5px;
    }
    .paid{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
    }

    .idContainer{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    .orderContainer {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;

    }

    .statusContainer {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
    }
    
    .respondBtn {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 4;
    }

}