.mainContainer {
	max-width: 1200px;
	margin: auto;
	font-family: "Mulish";
	width: 100%;
	padding: 0 20px 20px;
}

.clipLoader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;

}

.headerContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	line-height: 0;
	padding-top: 15px;
}

.title {
	font-size: 25px;
	margin-left: 5px;
	margin-right: 5px;
}

.mainContentContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 20px;
	gap: 20px;
}

.filterContainer {
	display: flex;
	justify-content: flex-end;
}
.contentContainer {
	width: 100%;
}

.btnColumn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 15px;
	margin-bottom: 15px;
}

.btn {
	width: 125px;
	height: 30px;
	border-radius: 5px;
	background-color: #000;
	color: #fff;
	cursor: pointer;
}

@media (max-width: 948px) {
	.headerPosition {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}

	.title {
		width: 100%;
	}

	.mainContentContainer {
		gap: 20px;
	}
}
