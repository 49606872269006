.container {
	position: relative;
	height: 100vh;
	width: 100%;
	max-width: 1200px;
	font-family: "Mulish";
	display: flex;
	flex-direction: column;
	margin: auto;
}
.spinner {
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .Headers{
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .main_heading{
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .payment-history-navigation{
    display: flex;
    justify-content: flex-start;
    
    padding-right: 10px;
    width: 355px;
    min-width: 355px;
  }
  .space{
    width: 100%;
  }
  .payment-history-navigation-button-active{
    z-index: 1;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: #000000;
    border: none;
    border-radius: 5px;
    font-family: "Mulish";
    font-size: 14px;
    width: 100%;
    height: 45px;
    min-width: 110px;
    max-width: 155px;
    margin-top: 10px;
    margin-bottom: 19px;
  }
  .payment-history-navigation-button{
    background-color: #f4f4f4;
    color: #000000;
    z-index: 1;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    font-family: "Mulish";
    font-size: 14px;
    width: 100%;
    height: 45px;
    min-width: 110px;
    max-width: 155px;
    margin-top: 10px;
    margin-bottom: 19px;
  }
  .back-Arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:16px;
    cursor: pointer;
  }
  .div-wrapper-body{
    padding-left: 20px;
    padding-right: 20px;
  }
  #header-fixed{
    position: fixed;
    top: 0;
    z-index: 1000;
  }
  .main-container{
	width: 100%;
	max-width: 1200px;
	/* margin: auto; */
	display: flex;
	flex-direction: column;
	font-family: "Mulish";
  }
  .title {
    font-weight: 600;
    font-size: 25px;
    margin-left: 5px;
    margin-right: 5px;
    font-family: Mulish;
    font-weight: 600;
    min-width: 190px;
    max-width: 190px;
  }
  .table-header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
  }
  .table-header{
    
    display: flex;
    flex:0.166;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    font-family: Mulish;
  }
  .table-header-payment{
    position: sticky;
    display: flex;
    flex:0.166;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    font-family: Mulish;
  }
  .table-header-payout{
    position: sticky;
    display: flex;
    flex:0.25;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    font-family: Mulish;
  }
  @media  (max-width:948px){
    .Headers{
      display: none;
      margin-bottom: 20px;
      
    }
    .table-header{
      display: none;
      margin-bottom: 20px;
    }
  }