.mainContainer {
	width: 100%;
	position: relative;
	height: auto;
	overflow: auto;
}

.activeBtnContainer {
	background: #bdbdbd;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;

	width: fit-content;
}
.btn {
	outline: none;
	background: transparent;
	border: none;
	font-weight: bolder;
	font-size: 14px;
	padding: 7px;
	cursor: pointer;
	color: #000;
}

.chart {
	width: 100% !important;
	height: auto !important;
	max-width: 100%;
}

.resetBtnContainer {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
/* @media screen and (max-width: 992px) {
	.chart {
		max-width: 900px !important;
		width: 900px !important;
	}
	.resetBtnContainer {
		max-width: 900px !important;
		width: 900px !important;
	}
} */
