.container {
	position: relative;
	height: 100vh;
	width: 100%;
	max-width: 1200px;
	font-family: "Mulish";
	display: flex;
	flex-direction: column;
	/* padding: 50px; */
	margin: auto;
	/* overflow: auto; */
}

.container::-webkit-scrollbar {
	display: none;
}

.navbar {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	line-height: 0;
	padding: 15px 15px 0 15px;
}

/* .backBtn {
    position: absolute;
    left: 0px;
} */

.title {
	font-weight: 600;
	font-size: 25px;
}
.regularHeader {
	background-color: white;
	border-radius: 10px;
	padding: 5px;
}
.regularTitle {
	font-weight: 800;
	font-size: 17px;
	margin: 5px 0;
}
.storeSection {
	display: flex;
	flex-direction: row;
	gap: 5px;
	height: 100%;
	width: 100%;
	margin-top: 20px;
	padding: 15px;
}
.regularHours {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: fit-content;
	background-color: #f4f4f4;
	border-radius: 10px;
	padding: 10px;
}
.regularHours::-webkit-scrollbar {
	display: none;
}

.specialHours {
	display: flex;
	flex-direction: column;
	height: fit-content;
	width: 100%;
	background-color: #f4f4f4;
	border-radius: 10px;
	padding: 10px;
}

.specialHours::-webkit-scrollbar {
	display: none;
}

.specialHeader {
	background-color: white;
	border-radius: 10px;
	padding: 5px;
}

.specialHourTitle {
	font-weight: 800;
	font-size: 17px;
	margin: 5px 0;
	font-family: "Mulish";
}

.timeSelect {
	width: 100%;
	height: 100%;
	margin-top: 10px;
}

.timeSelectTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background-color: white;
	border-radius: 10px;
	padding: 10px;
}

.editBtn {
	background-color: black;
	color: white;
	border-radius: 12px;
	border: none;
	height: 45px;
	width: 90px;
	font-size: 17px;
	cursor: pointer;
}

.dynamicBtn {
	background-color: black;
	color: white;
	border-radius: 12px;
	border: none;
	height: 45px;
	width: 90px;
	font-size: 17px;
	cursor: pointer;
}

.specialSelectTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	border-radius: 10px;
	/* font-size: 17px; */
	font-family: "Mulish";
	margin-top: 10px;
	padding: 10px;
	width: 100%;
}

#clipLoader {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

#timeTable {
	width: 100%;
}

#timeTable tr {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	margin-top: 10px;
	border-radius: 10px;
	padding: 10px;
	font-size: 16px;
	font-weight: 600;
}

.addBtn {
	background-color: black;
	color: white;
	border-radius: 12px;
	border: none;
	height: 45px;
	width: 110px;
	font-size: 17px;
	cursor: pointer;
}

#specialTable {
	width: 100%;
	height: fit-content;
	text-align: left;
	border-collapse: collapse;
	font-family: "Mulish";
}

#specialTable tr {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	margin-top: 10px;
	border-radius: 10px;
	padding: 10px;
    font-size: 16px;
	font-weight: 600;
}

@media (max-width: 948px) {
	.container {
		padding: 0 10px 0 10px;
	}

	.navbar {
		position: relative;
		left: -12px;
	}

	.title {
		margin-left: 10px;
	}

	.backBtn {
		position: relative;
		left: 0px;
	}

	.storeSection {
		flex-direction: column;
		gap: 5px;
		padding: 0;
	}

	#specialTable tr {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-template-rows: repeat(2, minmax(0, 1fr));
		gap: 25px;
	}

	.dynamicBtn {
		height: 45px;
		width: 100%;
	}

	#specialDate {
		justify-self: start;
		grid-column-start: 1;
		grid-column-end: 2;
	}

	#specialTime {
		justify-self: end;
		grid-column-start: 2;
		grid-column-end: 3;
	}

	#specialBtn {
		grid-column-start: 1;
		grid-column-end: 3;
	}
}
